<template>
  <section>
    <ShoppingProgress :step.sync="step" />
    <section class="pb-8">
      <!-- 訂單明細 -->
      <OrderDetail
        :IsReady="IsReady"
        :step.sync="step"
        :ShoppingData.sync="ShoppingData"
        :backTotalPrice.sync="backTotalPrice"
      />
      <div class="container">
        <!-- 收件人資料 -->
        <div class="row mb-3 mb-md-8">
          <div class="col-12">
            <el-form
              style="width: 100%;"
              :model="OrderForm"
              label-position="top"
              :rules="OrderRules"
              ref="OrderForm"
              class="cs-form bg-light p-3 p-md-6"
              data-style="2"
              @submit.prevent.native
            >
              <h4 class="mb-5">收件人資料</h4>
              <div class="px-md-3">
                <!-- 姓名 -->
                <div class="form-group row mb-2 ">
                  <label class="col-12 col-md-2 col-form-label" for="recipientName">
                    收件人姓名
                  </label>
                  <el-form-item prop="orderName" class="col-12 col-md-10">
                    <el-input
                      :class="`el-input__input`"
                      placeholder="收件人姓名"
                      type="text"
                      id="recipientName"
                      v-model="OrderForm.orderName"
                    />
                  </el-form-item>
                </div>

                <!-- 手機 -->
                <div class="form-group row mb-2 ">
                  <label class="col-12 col-md-2 col-form-label" for="recipientPhone">
                    收件人電話
                  </label>
                  <el-form-item prop="orderPhone" class="col-12 col-md-10">
                    <el-input
                      :class="`el-input__input`"
                      placeholder="收件人電話"
                      type="text"
                      id="recipientPhone"
                      v-model="OrderForm.orderPhone"
                    />
                  </el-form-item>
                </div>

                <!-- 收件人住址 -->
                <div class="form-group row mb-2 ">
                  <label class="col-12 col-md-2 col-form-label" for="recipientAddress">
                    收件人住址
                  </label>

                  <el-form-item prop="orderZipCode" class="col-12 col-md-2 mb-4">
                    <el-input
                      placeholder="郵遞區號"
                      v-model="OrderForm.orderZipCode"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="orderCounty" class="col-12 col-md-3 mb-4">
                    <el-select
                      :style="`display: block;`"
                      v-model="OrderForm.orderCounty"
                      @change="SetCityList(1, 'order')"
                      placeholder="請選擇縣市"
                    >
                      <el-option
                        v-for="item in CountyOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="orderCity" class="col-12 col-md-3 mb-4">
                    <el-select
                      :style="`display: block;`"
                      v-model="OrderForm.orderCity"
                      @change="SetZipCode('order')"
                      placeholder="請選擇地區"
                    >
                      <el-option
                        v-for="item in orderCityOption"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="orderAddress" class="col-12 col-md-10 offset-md-2">
                    <el-input
                      :class="`el-input__input`"
                      placeholder="地址"
                      type="text"
                      id="recipientAddress"
                      v-model="OrderForm.orderAddress"
                    />
                  </el-form-item>
                </div>

                <!-- 寄送注意事項 -->
                <div class="form-group row mb-2 mb-md-4">
                  <label class="col-12 col-md-2 col-form-label" for="recipientNote"
                    >寄送注意事項</label
                  >
                  <div class="col-12 col-md-10">
                    <textarea
                      class="form-control"
                      id="recipientNote"
                      rows="3"
                      placeholder=""
                      v-model="OrderForm.orderNote"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr />
              <h4 class="mb-2">付款方式</h4>
              <p class=" text-danger">
                ※因綠界網路ATM不支援手機版操作，手機版請勿選擇網路ATM。
              </p>
              <p class=" text-danger">
                ※訂單成立後請於指定天數內付款完成，訂單失敗後視為無效訂單。
              </p>
              <p class="mb-5 text-danger">
                刷卡、網路ATM：1天；現場繳費、超商付款、虛擬帳號：3 天
              </p>
              <el-form-item prop="orderPayMode">
                <div class="row px-md-3">
                  <template v-for="item in PayMode">
                    <div
                      class="col-12 col-md-3 mb-2"
                      :key="item.id"
                      v-if="
                        item.id > 0 &&
                          ((item.id !== 2 && item.id !== 5) ||
                            (item.id === 2 && backTotalPrice <= 20000) ||
                            (item.id === 5 && backTotalPrice >= 12000))
                      "
                    >
                      <button
                        type="button"
                        class="btn w-100 py-2"
                        :class="{
                          'btn-primary': OrderForm.orderPayMode === item.id,
                          'btn-outline-primary': OrderForm.orderPayMode !== item.id,
                        }"
                        @click="ChoosePay(item.id)"
                      >
                        {{ item.name }}
                      </button>
                    </div>
                  </template>
                </div>
              </el-form-item>
              <p class="mb-5 text-danger" v-if="OrderForm.orderPayMode === 5">
                ※以下銀行可分 (3期、6期、12期)
                中信、台新、玉山、富邦、遠東、永豐、國泰世華、華南、日盛、樂天、安泰、聯邦、兆豐、台中商銀、
                上海銀行、凱基、匯豐、星展、新光、合庫、彰銀、一銀、元大、陽信、台灣企銀、華泰、三信商銀
              </p>
              <div class="mb-5 text-danger" v-if="OrderForm.orderPayMode === 3">
                <p>網路ATM付款方式注意事項:</p>
                ※需配合讀卡機操作<br />
                ※僅支援Windows作業系統
              </div>
              <template v-if="OrderForm.orderPayMode !== 4">
                <hr />
                <h4 class="mb-2">發票資訊</h4>
                <p class="mb-5 text-danger">
                ※請確認資料是否正確，送出後將不可更改
                </p>
                <el-form-item prop="orderInvoiceType">
                  <div class="row px-md-3">
                    <template v-for="item in InvoiceMode">
                      <div
                        class="col-12 col-md-3 mb-2"
                        :key="`InvoiceMode_${item.id}`"
                        v-if="item.id !== 0"
                      >
                        <button
                          type="button"
                          class="btn w-100 py-2"
                          :class="{
                            'btn-primary': OrderForm.orderInvoiceType === item.id,
                            'btn-outline-primary': OrderForm.orderInvoiceType !== item.id,
                          }"
                          @click="ChooseInvoice(item.id)"
                        >
                          {{ item.name }}
                        </button>
                      </div>
                    </template>
                  </div>
                </el-form-item>
                <div class="px-md-3" v-if="OrderForm.orderInvoiceType !== 0">
                  <!-- 手機條碼 -->
                  <div class="form-group row mb-2"  v-if="OrderForm.orderInvoiceType === 2">
                    <label class="col-12 col-md-2 col-form-label" for="memberInvoiceCode">
                      手機條碼
                    </label>
                    <el-form-item
                      key="mobileCode"
                      prop="memberInvoiceCode"
                      class="col-12 col-md-10">
                      <label>「請輸入 "/"開頭，共8碼之手機條碼載具編號」</label>
                      <el-input
                        :class="`el-input__input`"
                        placeholder="手機條碼"
                        id="memberInvoiceCode"
                        type="text"
                        v-model="OrderForm.memberInvoiceCode"
                        @focus="memberInvoiceCodeCheckError = false"
                      />
                    </el-form-item>
                  </div>
                  <!-- 統一編號 -->
                  <div class="form-group row mb-2"  v-if="OrderForm.orderInvoiceType === 3">
                    <label class="col-12 col-md-2 col-form-label" for="memberInvoiceTaxId">
                      統一編號
                    </label>
                    <el-form-item
                      key="TaxIdCode"
                      prop="memberInvoiceTaxId"
                      class="col-12 col-md-10">
                      <el-input
                        :class="`el-input__input`"
                        placeholder="統一編號"
                        type="text"
                        id="memberInvoiceTaxId"
                        v-model="OrderForm.memberInvoiceTaxId"
                        maxlength="8"
                      />
                    </el-form-item>
                  </div>
                  <!-- 抬頭 -->
                  <div class="form-group row mb-2"  v-if="OrderForm.orderInvoiceType === 3">
                    <label class="col-12 col-md-2 col-form-label" for="memberInvoiceTitle">
                      抬頭
                    </label>
                    <el-form-item
                      key="InvoiceTitle"
                      prop="memberInvoiceTitle"
                      class="col-12 col-md-10">
                      <el-input
                        :class="`el-input__input`"
                        placeholder="抬頭"
                        type="text"
                        id="memberInvoiceTitle"
                        v-model="OrderForm.memberInvoiceTitle"
                      />
                    </el-form-item>
                  </div>
                  <!-- 手機號碼 -->
                  <div class="form-group row mb-2"  v-if="OrderForm.orderInvoiceType === 3">
                    <label class="col-12 col-md-2 col-form-label" for="memberInvoicePhone">
                      手機號碼
                    </label>
                    <el-form-item
                      key="InvoicePhone"
                      prop="memberInvoicePhone"
                      class="col-12 col-md-10">
                      <el-input
                        :class="`el-input__input`"
                        placeholder="手機號碼"
                        type="text"
                        id="memberInvoicePhone"
                        v-model="OrderForm.memberInvoicePhone"
                      />
                    </el-form-item>
                  </div>
                  <!-- 信箱 -->
                  <div class="form-group row mb-2"  v-if="OrderForm.orderInvoiceType === 3">
                    <label class="col-12 col-md-2 col-form-label" for="memberInvoiceEmail">
                      信箱
                    </label>
                    <el-form-item
                      key="InvoiceEmail"
                      prop="memberInvoiceEmail"
                      class="col-12 col-md-10">
                      <el-input
                        :class="`el-input__input`"
                        placeholder="信箱"
                        type="text"
                        id="memberInvoiceEmail"
                        v-model="OrderForm.memberInvoiceEmail"
                      />
                    </el-form-item>
                  </div>
                  <!-- 住址 -->
                  <div class="form-group row mb-2 " v-if="OrderForm.orderInvoiceType === 3">
                    <label class="col-12 col-md-2 col-form-label" for="memberInvoiceAddress">
                      住址
                    </label>

                    <el-form-item
                      key="InvoiceZipCode"
                      prop="memberInvoiceZipCode"
                      class="col-12 col-md-2 mb-4">
                      <el-input
                        placeholder="郵遞區號"
                        v-model="OrderForm.memberInvoiceZipCode"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      key="InvoiceCounty"
                      prop="memberInvoiceCounty"
                      class="col-12 col-md-3 mb-4">
                      <el-select
                        :style="`display: block;`"
                        v-model="OrderForm.memberInvoiceCounty"
                        @change="SetCityList(1, 'memberInvoice')"
                        placeholder="請選擇縣市"
                      >
                        <el-option
                          v-for="item in CountyOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      key="InvoiceCity"
                      prop="memberInvoiceCity"
                      class="col-12 col-md-3 mb-4">
                      <el-select
                        :style="`display: block;`"
                        v-model="OrderForm.memberInvoiceCity"
                        @change="SetZipCode('memberInvoice')"
                        placeholder="請選擇地區"
                      >
                        <el-option
                          v-for="item in memberInvoiceCityOption"
                          :key="item.label"
                          :label="item.label"
                          :value="item.label"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      key="InvoiceAddress"
                      prop="memberInvoiceAddress"
                      class="col-12 col-md-10 offset-md-2">
                      <el-input
                        :class="`el-input__input`"
                        placeholder="地址"
                        type="text"
                        id="recipientAddress"
                        v-model="OrderForm.memberInvoiceAddress"
                      />
                    </el-form-item>
                  </div>
                </div>
              </template>
            </el-form>
          </div>
        </div>
        <!-- 上一步/下一步按鈕 -->
        <div
          class="d-flex "
          :class="{
            'justify-content-end': orderId !== 0,
            'justify-content-between': orderId === 0,
          }"
        >
          <router-link
            v-if="orderId === 0"
            class="btn btn-outline-primary btn-hover-shadow px-6 py-2 py-md-3"
            to="/shopping/step1"
          >
            上一步
          </router-link>
          <a
            href=""
            class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3"
            @click.prevent="next"
          >
            前往結帳
          </a>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import ShoppingProgress from '@/components/ShoppingProgress.vue';
import OrderDetail from '@/components/Shopping/OrderDetail.vue';
import { GetCountyList, GetCityList, GetCityByZipCode } from '@/lib/zipcode';

import {
  GetShoppingCart,
  GetOrderPayMode,
  GetOrderInvoiceType,
  SaveShoppingCart,
  GetOrderCart,
  SaveOrderCart,
  CheckEcPayBarcode,
  CheckInvoiceUid,
} from '@/api/ShoppingCartApi';

export default {
  components: {
    ShoppingProgress,
    OrderDetail,
  },
  data() {
    const checkPayMode = (rule, value, callback) => {
      setTimeout(() => {
        if (this.OrderForm.orderPayMode === 0) {
          callback(new Error('請選擇付款方式'));
        } else {
          callback();
        }
      }, 1);
    };

    const checkInvoiceCode = (rule, value, callback) => {
      const patt = /^\/[0-9A-Z.+-]{7}$/;
      if (this.memberInvoiceCodeCheckError === true) {
        callback(new Error('手機載具碼錯誤'));
      } else if (!patt.test(value)) {
        callback(new Error('手機載具碼錯誤'));
      } else {
        callback();
      }
    };

    const checkInvoiceTaxId = (rule, value, callback) => {
      const calcAry = [1, 2, 1, 2, 1, 2, 4, 1];
      let checkState = true;
      // 組合驗證字串
      let sum = 0;

      // 檢核基本組合
      if (!/^[0-9]{8}$/.test(value)) {
        checkState = false;
      }

      // 計算字元的數值
      for (let i = 0; i < 8; i += 1) {
        const charInt = parseInt(value.charAt(i), 10);

        // 計算總字元數值(每個字元的權重不一樣以 calcAry 來計算)
        const charSum = charInt * calcAry[i];

        sum = sum + (charSum % 10) + ((charSum - (charSum % 10)) / 10);
      }

      // 如第7個數字是 7 總和 +1
      if (value.charAt(6) === '7') {
        sum += 1;
      }

      // 總和除 10 餘數不等於 0 時，返回錯誤
      if (sum % 10 !== 0) {
        checkState = false;
      }

      if (this.memberInvoiceCodeCheckError === true) {
        callback(new Error('統一編號錯誤'));
      } else if (checkState === false) {
        callback(new Error('統一編號錯誤'));
      } else {
        callback();
      }
    };

    const checkMobileFormat = (rule, value, callback) => {
      const patt = /^09[0-9]{8}$/;
      if (!patt.test(value)) {
        callback(new Error('請確認手機號碼格式'));
      } else {
        callback();
      }
    };

    return {
      orderId: 0,
      step: 1,
      ShoppingData: {
        orderId: 0,
        shoppings: [
          {
            courseId: 0,
            courseName: '',
            courseOriPrice: 0,
            coursePrice: 0,
            classCount: 0,
            shoppingCartDetails: [
              {
                classId: 0,
                className: '',
                subjectName: '',
                teacherName: '',
              },
            ],
          },
        ],
        discountId: 0,
        discountMode: 0,
        discountName: '',
        discountCode: '',
        discountType: 0,
        discountPrice: 0,
        discountPercent: 0,
        discountMoney: 0,
        orderName: '',
        orderPhone: '',
        orderZipCode: '',
        orderCounty: '',
        orderAddress: '',
        orderNote: '',
        orderPayMode: 0,
        orderPayModeName: '',
      },
      PayMode: {},
      InvoiceMode: {},
      IsReady: false,
      backTotalPrice: 0,
      memberInvoiceCodeCheckError: false,
      memberInvoiceTaxIdCheckError: false,
      OrderForm: {
        orderName: '',
        orderPhone: '',
        orderZipCode: '',
        orderCounty: '',
        orderCity: '',
        orderAddress: '',
        orderNote: '',
        orderPayMode: 0,
        orderPayModeName: '',
        orderInvoiceType: 0,
        orderInvoiceTypeName: '',
        memberInvoiceCode: '', // 行動條碼
        memberInvoiceTitle: '', // "抬頭",
        memberInvoiceAddress: '', // "地址",
        memberInvoiceCity: '', // "鄉鎮",
        memberInvoiceCounty: '', // "城市",
        memberInvoiceZipCode: '', // "郵遞區號",
        memberInvoicePhone: '', // "手機號碼",
        memberInvoiceEmail: '', // "信箱",
        memberInvoiceTaxId: '', // "統一編號",
      },
      OrderRules: {
        // 檢查輸入的值規則
        orderName: [{ required: true, message: '請輸入收件人姓名', trigger: 'blur' }],
        orderPhone: [{ required: true, message: '請輸入收件人電話', trigger: 'blur' }],
        orderCounty: [{ required: true, message: '請選擇縣市', trigger: 'change' }],
        orderCity: [{ required: true, message: '請選擇地區', trigger: 'change' }],
        orderAddress: [{ required: true, message: '請輸入住址', trigger: 'blur' }],
        orderPayMode: [{ validator: checkPayMode, trigger: 'blur' }],
        orderInvoiceType: [{ required: true, message: '請選擇發票資訊', trigger: 'blur' }],
        memberInvoiceCode: [
          { required: true, message: '請輸入手機條碼', trigger: 'blur' },
          { validator: checkInvoiceCode, trigger: 'blur' },
        ],
        memberInvoiceTaxId: [
          { required: true, message: '請輸入統一編號', trigger: 'blur' },
          { validator: checkInvoiceTaxId, trigger: 'blur' },
        ],
        memberInvoiceTitle: [{ required: true, message: '請輸入抬頭', trigger: 'change' }],
        memberInvoicePhone: [
          { required: true, message: '請輸入手機號碼', trigger: 'change' },
          {
            trigger: ['blur', 'change'], min: 10, max: 10, message: '手機號碼必須為10碼',
          },
          { trigger: ['blur', 'change'], validator: checkMobileFormat },
        ],
        memberInvoiceEmail: [
          { required: true, message: '請輸入信箱', trigger: 'blur' },
          { type: 'email', message: '請輸入正確的信箱格式', trigger: ['blur', 'change'] },
        ],
        memberInvoiceCounty: [{ required: true, message: '請選擇縣市', trigger: 'change' }],
        memberInvoiceCity: [{ required: true, message: '請選擇地區', trigger: 'change' }],
        memberInvoiceAddress: [{ required: true, message: '請輸入住址', trigger: 'blur' }],
      },
      CountyOption: [],
      orderCityOption: [],
      memberInvoiceCityOption: [],
      TmpInvoice: {
        memberInvoiceCode: '', // 行動條碼
        memberInvoiceTitle: '', // "抬頭",
        memberInvoiceAddress: '', // "地址",
        memberInvoiceCity: '', // "鄉鎮",
        memberInvoiceCounty: '', // "城市",
        memberInvoiceZipCode: '', // "郵遞區號",
        memberInvoicePhone: '', // "電話",
        memberInvoiceEmail: '', // "信箱",
        memberInvoiceTaxId: '', // "統一編號",
      },
    };
  },
  async created() {
    const { orderId } = this.$route.params;
    if (orderId !== undefined) this.orderId = orderId;
    await this.GetShoppingCart();

    this.SetCountyList();
    this.SetCityList(0, 'order');
    this.SetCityList(0, 'memberInvoice');

    this.SetZipCode('order');
    this.SetZipCode('memberInvoice');
    await Promise.all([
      this.GetOrderPayMode(),
      this.GetOrderInvoiceType(),
    ]);
  },
  methods: {
    SetCountyList() {
      this.CountyOption = GetCountyList();
    },
    SetCityList(clear, name) {
      this[`${name}CityOption`] = GetCityList(this.OrderForm[`${name}County`]);
      if (clear === 1) {
        this.OrderForm[`${name}City`] = '';
        this.OrderForm[`${name}ZipCode`] = '';
      } else {
        const data = this[`${name}CityOption`].filter((item) => this.OrderForm[`${name}City`] === item.label);
        if (!data[0]) {
          this.OrderForm[`${name}City`] = '';
          this.OrderForm[`${name}ZipCode`] = '';
        }
      }
    },
    SetZipCode(name) {
      const data = this[`${name}CityOption`].filter((item) => this.OrderForm[`${name}City`] === item.label);
      if (data[0]) {
        this.OrderForm[`${name}ZipCode`] = data[0].value;
      } else {
        this.OrderForm[`${name}ZipCode`] = '';
      }
    },
    ChooseInvoice(id) {
      this.OrderForm.orderInvoiceType = id;
      this.$refs.OrderForm.clearValidate('orderInvoiceType');
    },
    ChoosePay(id) {
      this.OrderForm.orderPayMode = id;
      this.$refs.OrderForm.clearValidate('orderPayMode');

      // 繳費方式不為現場，但發票為紙本發票；就預設為電子發票
      if (id !== 4 && this.OrderForm.orderInvoiceType === 0) {
        this.OrderForm.orderInvoiceType = 1;
      } else if (id === 4 && this.OrderForm.orderInvoiceType !== 0) {
        this.OrderForm.orderInvoiceType = 0;
      }
    },
    GetShoppingCart() {
      let GetWay = GetShoppingCart;
      if (this.orderId !== 0) GetWay = GetOrderCart;
      return GetWay(this.orderId).then((response) => {
        if (response.data.statusCode === 0) {
          this.ShoppingData = response.data.data;
          if (this.ShoppingData.shoppings.length === 0) this.$router.replace('/');
          this._.merge(this.OrderForm, this._.pick(this.ShoppingData, this._.keys(this.OrderForm)));
          if (this.OrderForm.orderCity === null || this.OrderForm.orderCity === '') {
            this.OrderForm.orderCity = GetCityByZipCode(this.OrderForm.orderZipCode);
          }
          this.TmpInvoice = this._.pick(this.ShoppingData, this._.keys(this.TmpInvoice));

          this.ShoppingData.shoppings.forEach((item) => {
            if (item.courseType === 2) {
              item.optionalChooseList.forEach((item2) => {
                // eslint-disable-next-line no-param-reassign
                this.$set(item2, 'teacherChoose', []);
                item2.optionalTeacherList.forEach((item3) => {
                  // eslint-disable-next-line no-param-reassign
                  if (item3.hasTeacher) {
                    item2.teacherChoose.push(item3.teacherId);
                  }
                });
              });
            }
          });
          this.IsReady = true;
        }
      });
    },
    GetOrderInvoiceType() {
      return new Promise((resolve, reject) => {
        GetOrderInvoiceType(0)
          .then((response) => {
            if (response.data.statusCode === 0) {
              this.InvoiceMode = response.data.data;
            }

            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    GetOrderPayMode() {
      return new Promise((resolve, reject) => {
        GetOrderPayMode(0)
          .then((response) => {
            if (response.data.statusCode === 0) {
              this.PayMode = response.data.data;
            }

            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    CheckEcPayBarcode() {
      return new Promise((resolve, reject) => {
        const postData = {
          verificationType: 3,
          verificationData: this.OrderForm.memberInvoiceCode,
        };
        CheckEcPayBarcode(postData)
          .then((response) => {
            this.memberInvoiceCodeCheckError = true;
            if (response.data.statusCode === 0) {
              this.memberInvoiceCodeCheckError = false;
            }
            this.$refs.OrderForm.validateField('memberInvoiceCode');
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    CheckInvoiceUid() {
      return new Promise((resolve, reject) => {
        const postData = {
          Code: this.OrderForm.memberInvoiceTaxId,
        };
        CheckInvoiceUid(postData)
          .then((response) => {
            this.memberInvoiceTaxIdCheckError = true;
            if (response.data.statusCode === 0) {
              this.memberInvoiceTaxIdCheckError = false;
            }
            this.$refs.OrderForm.validateField('memberInvoiceTaxId');
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    async next() {
      this.$refs.OrderForm.validate(async (valid) => {
        if (valid) {
          // 手機條碼才檢查
          if (this.OrderForm.orderInvoiceType === 2) {
            await this.CheckEcPayBarcode();
            if (this.memberInvoiceCodeCheckError === true) {
              return;
            }
          }

          // 統一編號
          if (this.OrderForm.orderInvoiceType === 3) {
            await this.CheckInvoiceUid();
            if (this.memberInvoiceTaxIdCheckError === true) {
              return;
            }
          }

          const PayModeName = this._.find(this.PayMode, { id: this.OrderForm.orderPayMode }).name;
          const InvoiceTypeName = this._.find(this.InvoiceMode,
            {
              id: this.OrderForm.orderInvoiceType,
            }).name;

          this.OrderForm.orderInvoiceTypeName = InvoiceTypeName;
          this.OrderForm.orderPayModeName = PayModeName;

          this._.forEach(this._.keys(this.TmpInvoice), (key) => {
            // 紙本與電子發票不更新原本的資料
            if (this.OrderForm.orderInvoiceType === 0 || this.OrderForm.orderInvoiceType === 1) {
              this.OrderForm[key] = this.TmpInvoice[key];
            } else {
              // 行動條碼的方式，其他參數不異動
              if (this.OrderForm.orderInvoiceType === 2 && key !== 'memberInvoiceCode') {
                this.OrderForm[key] = this.TmpInvoice[key];
              }
              // 其他方式，則是不修改行動條碼
              if (this.OrderForm.orderInvoiceType !== 2 && key === 'memberInvoiceCode') {
                this.OrderForm[key] = this.TmpInvoice[key];
              }
            }
          });

          this._.merge(this.ShoppingData, this.OrderForm);
          let SaveWay = SaveShoppingCart;
          if (this.orderId !== 0) SaveWay = SaveOrderCart;

          SaveWay(this.ShoppingData).then((response) => {
            if (response.data.statusCode === 0) {
              if (this.orderId !== 0) {
                this.$router.push({
                  name: 'Step3',
                  params: { orderId: this.orderId, check: 'check' },
                });
              } else {
                this.$router.push({ name: 'Step3', params: { check: 'check' } });
              }
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
  },
};
</script>

<style></style>
